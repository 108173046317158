import gsap from 'gsap'

export default class Header {
  constructor() {
    this.DOM = { el: document.querySelector('header') }
    this.DOM.headerType = this.DOM.el.querySelector('.header-type')
    this.DOM.toggleIcon = this.DOM.el.querySelector('.menu_toggle')
    this.DOM.mobileMenu = this.DOM.el.querySelector('.mobile-sidebar')
    this.DOM.mobileIcons = this.DOM.el.querySelector('.mobile-icons-bottom')
    this.DOM.pageBackground = document.querySelector('.page-cover')
    this.DOM.fullScreenNav = this.DOM.el.querySelector('.fullscreen-nav-js')
    this.DOM.revealActive = this.DOM.el.classList.contains(
      'header__activate_scrolling'
    )
    this.DOM.menuItem = [
      ...document.querySelectorAll('.menu-item-has-children a:not(.sub-menu a)')
    ]
    this.DOM.menuItemHasChildren = [
      ...document.querySelectorAll('.menu-item-has-children')
    ]
    this.DOM.subMenus = [...document.querySelectorAll('.sub-menu')]
    this.screenLarge = window.matchMedia('(min-width: 992px)')
    this.scrollUp = 'js-scroll-up'
    this.scrollDown = 'js-scroll-down'
    this.lastScroll = 0
    this.windowX = null
    this.winY = null
    this.init()
  }

  toggleSubMenu(event) {
    const icon = event.currentTarget
    event.preventDefault()

    const currentSubMenu = icon.parentNode.querySelector('.sub-menu')

    if (this.DOM.headerType.classList.contains('logo_left')) {
      // Logo Left
    } else if (this.DOM.headerType.classList.contains('logo_middle')) {
      if (Array.isArray(this.DOM.subMenus)) {
        // close all other subMenus when selecting a new submenu
        this.DOM.subMenus.forEach((menu) => {
          menu.classList.remove('active-menu')
        })

        this.closeDropdowns()

        // on fullscreen with multiple dropdowns choose selected dropdowns
        if (icon.classList.contains('open-menu')) {
          icon.classList.remove('open-menu')
        } else {
          icon.classList.add('open-menu')
        }
      }
    } else {
    }

    //  console.log(icon)

    //show or hide each menu
    if (icon.classList.contains('js-open')) {
      currentSubMenu.classList.remove('active-menu')
      icon.classList.remove('js-open')
      //   console.log('x')

      // on first click fullscreen nav menu with multiple submenus
    } else if (
      icon.classList.contains('open-menu') &&
      !icon.classList.contains('js-open')
    ) {
      currentSubMenu.classList.add('active-menu')
      icon.classList.add('js-open')
      // console.log('g')
    } else if (
      !icon.classList.contains('js-open') &&
      this.DOM.headerType.classList.contains('logo_middle') &&
      Array.isArray(this.DOM.subMenus)
    ) {
      currentSubMenu.classList.remove('active-menu')
      icon.classList.remove('js-open')
      // console.log('z')
    } else {
      currentSubMenu.classList.add('active-menu')
      icon.classList.add('js-open')
      // console.log('gvvcc')
    }
  }

  init() {
    this.createDropdownIcon()
    this.eventListeners()
  }

  createDropdownIcon() {
    if (this.DOM.menuItem) {
      if (Array.isArray(this.DOM.menuItem)) {
        // an array
        this.DOM.menuItem.forEach((item) => {
          // create a plus span
          const plus = `<button class='arrow-down' aria-label='Right Align'></button>`
          item.insertAdjacentHTML('afterend', plus)
        })
      } else {
        // a single element
        // create a plus span
        const plus = `<button class='arrow-down' aria-label='Right Align'></button>`
        this.DOM.menuItem.insertAdjacentHTML('afterend', plus)
      }
    }
  }

  removeCover() {
    if (this.screenLarge.matches) {
      this.DOM.pageBackground.classList.remove('page-cover-opacity')
    }
  }

  eventListeners() {
    if (this.DOM.toggleIcon) {
      this.DOM.toggleIcon.addEventListener('click', this.toggleMenu.bind(this))
    }
    window.addEventListener('resize', this.removeCover.bind(this))

    if (this.DOM.menuItem) {
      if (Array.isArray(this.DOM.menuItem)) {
        // an array
        this.DOM.menuItemHasChildren.forEach((element) => {
          const icon = element.querySelector('.arrow-down')
          if (icon) {
            icon.addEventListener('click', this.toggleSubMenu.bind(this))
          }
        })
      } else {
        // a single element
        this.DOM.menuItemHasChildren
          .querySelector('.arrow-down')
          .addEventListener('click', this.toggleSubMenu.bind(this))
      }
    }
  }

  toggleMenu() {
    this.DOM.toggleIcon.classList.toggle('toggle_on')

    // IF we have logo - left header selected
    if (this.DOM.mobileMenu) {
      this.DOM.mobileMenu.classList.toggle('mobile-menu-appear')
      this.DOM.pageBackground.classList.toggle('page-cover-opacity')
      this.DOM.mobileIcons.classList.toggle('visible');
      //   this.DOM.page.classList.toggle('paged');
    }
    if (this.DOM.headerType.classList.contains('logo_left')) return
    // IF we have logo middle selected
    // This will work if scrolling header is remvoed otherwise please check below in scorlling function
    if (this.DOM.fullScreenNav.classList.contains('opacity-full')) {
      this.DOM.fullScreenNav.classList.remove('opacity-full')
      // Remove listener to re-enable scroll
      document.querySelector('body').style.overflow = 'visible'
    } else {
      this.DOM.fullScreenNav.classList.add('opacity-full')
      // disable scroll when fullscreen nav is visible
      document.querySelector('body').style.overflow = 'hidden'
    }
  }

  closeDropdowns() {
    const dropdwns = [...document.querySelectorAll('.arrow-down')]
    dropdwns.forEach((toggle) => toggle.classList.remove('js-open'))
  }

  scrolling(e) {
    // WHEN YOU SCROLL DOWN HIDE HEADER, REVEAL WHEN SCROLLING UP

    // console.log(this)
    // accessed through index.js hence why we need to use 'header.'

    if (this.header.DOM.revealActive != null) {
      this.header.currentScroll = window.pageYOffset

      if (this.header.currentScroll === 0) {
        this.header.DOM.el.classList.remove(this.header.scrollUp)
        return
      }

      if (
        this.header.currentScroll > this.header.lastScroll &&
        !this.header.DOM.el.classList.contains(this.header.scrollDown)
      ) {
        // DOWN
        if (this.header.DOM.fullScreenNav) {
          this.header.DOM.fullScreenNav.classList.contains('opacity-full')
            ? ''
            : ''
        } else {
          this.header.DOM.el.classList.remove(this.header.scrollUp)
          this.header.DOM.el.classList.add(this.header.scrollDown)

          // CLOSE ALL SUBMENUS on scroll down
          if (this.header.DOM.subMenus) {
            this.header.DOM.subMenus.forEach((menu) => {
              menu.classList.remove('active-menu')
              menu.classList.remove('open-menu')
            })
          }

          this.header.closeDropdowns()
        }

        // this.header.DOM.toggleIcon.classList.remove('toggle_on')
      } else if (
        this.header.currentScroll < this.header.lastScroll &&
        this.header.DOM.el.classList.contains(this.header.scrollDown)
      ) {
        // UP
        this.header.DOM.el.classList.remove(this.header.scrollDown)
        this.header.DOM.el.classList.add(this.header.scrollUp)
      }
      this.header.lastScroll = this.header.currentScroll
    }
  }
}
