import Highway from '@dogstudio/highway';
import Renderer from './renderer';
import Transition from './transition';

export default class HighwayPageTranstions {
  constructor({scroll, body, blockManager, heroManager, cursor, header}) {
    this.scroll = scroll
    this.body = body
    this.BlockManager = blockManager
    this.HeroManager = heroManager
    this.cursor = cursor
    this.header = header
    this.links = document.querySelectorAll('header a');
    this.adminBarLinks = document.querySelectorAll('#wpadminbar a')
    this.init()
  }

  create() {
    console.log('yo')
    this.H = new Highway.Core({
      renderers: {
        default: Renderer
      },
      transitions: {
        default: Transition
      }
    })
    this.H.detach(this.adminBarLinks)
  }

  init() {
    this.create()
    this.addEventListeners()
  }

  addEventListeners() {
    this.H.on('NAVIGATE_IN', ({to, trigger, location}) => {
      // console.log('NAVIGATE_IN');
      this.HeroManager.enterPageTransition()
    })
    
    this.H.on('NAVIGATE_OUT', ({from, trigger, location}) => {
      // console.log('NAVIGATE_OUT');
      this.BlockManager.deleteBlocks()
      this.HeroManager.deleteHero()

      this.links.forEach(a => {
        a.classList.toggle('active', a.pathname === location.pathname);
      })
    })
    
    this.H.on('NAVIGATE_END', ({from, to, trigger, location}) => {
      // console.log('NAVIGATE_END');
      this.BlockManager.enterPageTransition()

      // reinit cursor enter and leave
      if (this.cursor) {
          // Mouse effects on all links and others
          [...document.querySelectorAll('a, button, .accordian-item')].forEach(link => {
              link.addEventListener('mouseenter', () => this.cursor.enter());
              link.addEventListener('mouseleave', () => this.cursor.leave());
          })
      }

        // Analytics
          if (typeof gtag !== 'undefined') {
              // eslint-disable-next-line
              gtag('config', 'UA-XXXXXXXXX-X', {
              'page_path': location.pathname,
              'page_title': to.page.title,
              'page_location': location.href
              })
          }

          // Query admin bar links, and new page's admin bar links
          const adminBarLinks = document.querySelectorAll('#wpadminbar a')
          const newAdminBarLinks = to.page.body.querySelectorAll('#wpadminbar a')

          // Replace every admin bar link's href value with new value
          for (let i = 0; i < newAdminBarLinks.length; i++) {
              adminBarLinks[i].href = newAdminBarLinks[i].href
          }

          // Detach admin bar links from Highway
          this.H.detach(adminBarLinks)
    })  
  }
}


// const adminBarLinks = document.querySelectorAll('#wpadminbar a');
// const H = new Highway.Core({
//   renderers: {
//     default: Renderer
//   },
//   transitions: {
//     default: Transition
//   }
// });
// H.detach(adminBarLinks);
// export default H