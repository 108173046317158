import gsap from "gsap"

export default class Modal {
  constructor() {
    this.body = document.querySelector("body")
    this.DOM = { el: document.querySelector('.modal-group') }
    this.DOM.modal = this.DOM.el.querySelector('.modal')
    this.DOM.slider = this.DOM.el.querySelector('#modal-slider')
    this.DOM.close = this.DOM.el.querySelector('.close-group')
    this.DOM.pageBackground = document.querySelector(".page-cover")
    gsap.set(this.DOM.modal, { yPercent: 20, autoAlpha: 0 })

    this.activate()
    this.addEventListeners()
  }

  activate() {
    // grab how many days the modal should be shown again after
    let days = parseInt(this.DOM.slider.textContent)
    document.querySelector('body').style.overflow = 'hidden'

    // SHOW POP UP AGAIN AFTER SO MANY

    // if days set to 1 show pop up everytime
    if (days === 1) {
      localStorage.last = Date.now()

      //Show the div because you haven't ever shown it before.
      this.showModal()

    } else {
      if (localStorage.last) {
        if (
          (localStorage.last - Date.now()) / (1000 * 60 * 60 * 24 * days) >=
          1
        ) {
          //Date.now() is in milliseconds, so convert it all to days, and if it's more than 1 day, show the div
          this.showModal() //Show the div
          localStorage.last = Date.now() //Reset your timer
        }
      } else {
        localStorage.last = Date.now()
        this.showModal() //Show the div because you haven't ever shown it before.
      }
    }
  }

  showModal() {
    gsap.to(this.DOM.modal, {
      yPercent: 0,
      ease: "expo.out",
      duration: 0.4,
      autoAlpha: 1,
      onEnter: () => {
        this.DOM.pageBackground.classList.add("page-cover-opacity")
      },
      onComplete: () => {
        this.DOM.modal.classList.remove("hide-modal")
        this.body.style.overflow = 'hidden'
      },
    })

  }

  hideModal() {
    gsap.to(this.DOM.modal, {
      yPercent: 20,
      ease: "expo.in",
      duration: 0.4,
      autoAlpha: 0,
      onComplete: () => {
        this.DOM.modal.classList.add("hide-modal")
        this.DOM.pageBackground.classList.remove("page-cover-opacity")
        this.body.style.overflow = 'visible'
      },
    })
  }

  close() {
    this.hideModal()
    // setTimeout(() => {
    //   this.DOM.el.parentNode.removeChild(this.DOM.el)
    // }, 2000)
  }

  addEventListeners() {
    this.DOM.close.addEventListener('click', this.close.bind(this))
  }
}
