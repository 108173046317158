import Highway from '@dogstudio/highway'
import gsap from 'gsap'

class Transition extends Highway.Transition {

  in({ from, to, done, trigger }) {
     // Reset Scroll
     window.scrollTo(0, 0);

     // Remove Old View
     from.remove();

     //animation
    gsap.fromTo(
      to,
      { 
        opacity: 0,
        y: -10 
      },
      {
        opacity: 1,
        y:0,
        onComplete: done
      }
    )
  }

  out({ from, to, done, trigger }) {
      
   // Animation
    gsap.fromTo(
      from,
      { 
        opacity: 1,
        y: 0 
      },
      {
        opacity: 0,
        y: -10,
        onComplete: done
      }
    )
  }
}

export default Transition