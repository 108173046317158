import Cursor from '../../Components/Cursor'

export default class Palette{
    constructor (id) {
        this.DOM = {el: document.getElementById(id)}
        this.DOM.colours = [...this.DOM.el.querySelectorAll('.js-colour')]
        this.DOM.tooltip = {el: document.querySelector('.js-tooltip')}
        this.DOM.tooltip.arrow = this.DOM.tooltip.el.querySelector('.js-arrow')
        // Initialize custom cursor
        this.mouse = { x: 0, y: 0 }
        this.cursor = new Cursor({ mouse: this.mouse, cursor: this.DOM.tooltip.el  })
        this.init()
    }

    copyColour () {
        navigator.clipboard.writeText(this.hex);
        this.DOM.tooltip.el.innerHTML = "Copied: " + this.hex;
    }


    updateInfo (event) {
        this.hex = event.currentTarget.dataset.colour
        // this.DOM.tooltip.el.style.display = 'block';
        this.DOM.tooltip.el.innerHTML = "Copy: " + this.hex;
        this.DOM.tooltip.el.style.background = this.hex;
    }

    removeText () {
        this.DOM.tooltip.el.innerHTML = 'Copy to clipboard'
        this.DOM.tooltip.el.style.background = '#2b383f';
    }

    showTooltip (event) {
      this.cursor.show()
    }

    hideTooltip () {
        this.cursor.hide()
    }

    addEventListeners() {

        this.DOM.colours.forEach(colour => {
            colour.addEventListener('click', this.copyColour.bind(this))
        })

        this.DOM.colours.forEach(colour => {
            colour.addEventListener('mousemove', this.updateInfo.bind(this))
            colour.addEventListener('mouseleave', this.removeText.bind(this))
        })

        this.DOM.el.addEventListener('mousemove', this.showTooltip.bind(this))
        this.DOM.el.addEventListener('mouseleave', this.hideTooltip.bind(this))
    }

    onResize () { }
    gutenberg() {
        if (window.acf) {
            this.init()
        }
    }
    
    init() {
        this.addEventListeners()
        this.onResize()
     }
}