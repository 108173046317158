import Highway from '@dogstudio/highway';

class Renderer extends Highway.Renderer {
  onEnter() {
    // console.log('Renderer.onEnter');
    // H.emit('ENTER');
  }
  onEnterCompleted() {
    // console.log('Renderer.onEnterCompleted');
    // H.emit('ENTER_COMPLETE');
  }
  onLeave() {
    // console.log('Renderer.onLeave');
    // H.emit('LEAVE');
  }
  onLeaveCompleted() {
    // console.log('Renderer.onLeaveCompleted');
    // H.emit('LEAVE_COMPLETE');
  }
}

export default Renderer
