import Timeline from '../Blocks/page/Timeline'
// import Services from '../Blocks/page/Services'
import Testimonials from '../Blocks/page/Testimonials'
import Gallery from '../Blocks/page/Gallery'
import Accordion from '../Blocks/page/Accordion'
import List from '../Blocks/page/List'
import FAQ from '../Blocks/page/Faq'
import PostDisplay from '../Blocks/Post/PostDisplay'
import Slideshow from '../Blocks/page/Slideshow'
import ImageSection from '../Blocks/page/ImageSection'
import FullWidthImage from '../Blocks/page/FullWidthImage'
import ContactSection from '../Blocks/page/ContactSection'
import Palette from '../Blocks/Page/Palette'

// import ImageSection from 'Blocks/Page/ImageSection'

export default class BlockManager {
  constructor({ blocks, scroll, wrapper, screenSize }) {
    this.blocks = blocks
    this.scroll = scroll
    this.screenSize = screenSize
    this.scrollWrapper = wrapper
    this.currentBlocks = []
    this.onFirstPageLoad()

    if (typeof wp != 'undefined' && wp.blockEditor) {
      this.gutenberg()
    }
  }

  onFirstPageLoad() {
    //  console.log(this.blocks)
    this.blocks.forEach((block) => {
      this.blockCheck(block)
    })
  }

  blockCheck(block) {
    if (!this.currentBlocks) {
      this.currentBlocks = []
    }

    const className = block.className
    const data = block.dataset.block
    const id = block.id

    console.log(className, id, data)
    // split classnames into an array
    let keys = className.split(' ')
    // filter out any classes that dont match data-block
    keys = keys.filter((cl) => cl === data)
    console.log(keys)

    // flatten array
    keys = keys[0]

    switch (keys) {
      case 'PostDisplay':
        const pd = new PostDisplay(id)
        this.currentBlocks.push(pd)
        break
      case 'Testimonials':
        const t = new Testimonials({
          scroll: this.scroll,
          container: this.scrollWrapper,
          id: id
        })
        this.currentBlocks.push(t)
        break
      case 'ContactSection':
        const cs = new ContactSection(id)
        this.currentBlocks.push(cs)
        break
      case 'Slideshow':
        const s = new Slideshow({
          scroll: this.scroll,
          container: this.scrollWrapper,
          id: id
        })
        this.currentBlocks.push(s)
        break
      case 'Accordion':
        const a = new Accordion({
          scroll: this.scroll,
          container: this.scrollWrapper,
          id: id
        })
        this.currentBlocks.push(a)
        break
      case 'List':
        const l = new List(id)
        this.currentBlocks.push(l)
        break
      case 'Palette':
        const pa = new Palette(id)
        this.currentBlocks.push(pa)
        break
      case 'FullWidthImage':
        const fwi = new FullWidthImage({
          scroll: this.scroll,
          screenSize: this.screenSize,
          container: this.scrollWrapper,
          id: id
        })
        this.currentBlocks.push(fwi)
        break
      case 'ImageSection':
        const is = new ImageSection({ screenSize: this.screenSize, id: id })
        this.currentBlocks.push(is)
        break
      case 'Gallery':
        const g = new Gallery(id)
        this.currentBlocks.push(g)
        break
      case 'FAQ':
        const faq = new FAQ({
          scroll: this.scroll,
          container: this.scrollWrapper,
          screenSize: this.screenSize,
          id: id
        })
        this.currentBlocks.push(faq)
        break
      case 'Timeline':
        const tl = new Timeline({
          scroll: this.scroll,
          container: this.scrollWrapper,
          screenSize: this.screenSize,
          id: id
        })
        this.currentBlocks.push(tl)
        break
    }    
  }

  deleteBlocks() {
    if (this.currentBlocks) {
      delete this.currentBlocks
    }
  }

  enterPageTransition(next) {
    // init
    const blocksOnNewPage = [...document.querySelectorAll('[data-block]')]

    blocksOnNewPage.forEach((block) => {
      this.blockCheck(block)
    })
  }

  // does block need to enable gutenberg  back end editing
  gutenberg() {
    this.currentBlocks.forEach((block) => {
      if (block.gutenberg) block.gutenberg()
    })
  }

  onResize(screenSize) {
    this.screenSize = screenSize

    this.currentBlocks.forEach((block) => {
      if (block.onResize) {
        block.onResize(this.screenSize)
      }
      // this.blocks[blocks].onResize(this.screenSize)
    })

    //  console.log('blocks resize')
  }
}
